<template>
    <div id="payletter" class="payletter">
        <div v-show="show" ref="screen" class="screen" style="display: none;">
            <iframe ref="iframe" width="100%" height="700" frameborder="0" scrolling="true"></iframe>
        </div>
    
        <div v-show="show" ref="progress" class="progress" style="display: none;">
            <div class="spinner"></div>
        </div>
        <slot name="activator" :on="{ click: open }" />
    </div>
</template>

<script>
export default {
    props: {
        order: Object
    },
    data() {
        let {
            orderNo,
        } = this.$props.order;

        return {
            show: false,
            
            // 결제정보
            orderNo,
        };
    },
    mounted() {
        window.payletterComplete = (payload) => {
            this.$emit("complete", payload);
            this.$emit("change", payload);
            this.$emit("input", payload);
            this.show = false;
        };
    },
    beforeDestroy() {
        window.payletterComplete = undefined;
    },
    methods: {
        async pay() {
            this.show = true;
            this.$nextTick(() => {
                this.$refs.screen.style.display = "";
                this.$refs.progress.style.display = "";
                this.$refs.iframe.src = `/plugins/payletter/request?` + Object.keys(this.$data).reduce((query, key) => query + `${key}=${encodeURIComponent(this.$data[key])}&`, ``);
            });
        },
    },
};
</script>

<style scoped>
#payletter .screen {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 99999;
    overflow-y: scroll;
    background: rgba(0,0,0,0.5);
}
#payletter iframe {
    position: fixed;
    width: 100%;
    height: 100%;
}
.payletter .progress{
    position: fixed;
    width: 100%;
    height: 100%;
    left:0;
    top: 0;
    background: rgba(0,0,0,0.54);
}
.payletter .spinner {
    position: fixed;
    margin: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 28px;
    width: 28px;
    animation: rotate 0.8s infinite linear;
    border: 8px solid #fff;
    border-right-color: transparent;
    border-radius: 50%;
}
@keyframes rotate {
    0%    { transform: rotate(0deg); }
    100%  { transform: rotate(360deg); }
}
</style>
