<template>
    <div id="toss" class="toss">
        <div v-if="show" ref="screen" class="screen">
            <div ref="tosspayments" class="tosspayments">
                <!-- 결제 UI -->
                <div id="payment-method"></div>
                <!-- 이용약관 UI -->
                <div id="agreement"></div>
                <!-- 결제하기 버튼 -->
                <div class="btn-wrapper">
                    <button class="btn" id="payment-button" @click="request()">결제하기</button>
                    <button class="btn cancel" id="payment-button" @click="cancel()">취소하기</button>
                </div>
            </div>
        </div>
    
        <div v-if="show" ref="progress" class="progress">
            <div class="spinner"></div>
        </div>
    </div>
</template>

<script>
import api from "@/api";
import { loadTossPayments, ANONYMOUS } from "@tosspayments/tosspayments-sdk";

export default {
    props: {
        order: Object
    },
    data() {
        return {
            show: false,
            tossPayments: null,
            widgets: null,
        };
    },
    mounted() {
        window.tossComplete = (payload) => {
            this.$emit("complete", payload);
            this.$emit("change", payload);
            this.$emit("input", payload);
            this.show = false;
        };
        window.tossCancel = () => {
            this.show = false;
        }
    },
    beforeDestroy() {
        window.tossComplete = undefined;
        window.tossCancel = undefined;
    },
    methods: {
        async pay() {
            this.show = true;
            this.$nextTick(() => {
                this.init();
            })
        },
        async init() {
            let { order, clientKey } = await api.plugins.toss.tosspayments.request.get({ params: { orderNo: this.order.orderNo } });

            var tossPayments = this.tossPayments = await loadTossPayments(clientKey);
            var widgets = this.widgets = tossPayments.widgets({
                customerKey: "q0dc9ERyZFRYNGkcfS3YQ",
            });

            // ------ 주문의 결제 금액 설정 ------
            await widgets.setAmount({
                currency: "KRW",
                value: order.paymentAmount,
            });

            // ------  결제 UI 렌더링 ------
            await widgets.renderPaymentMethods({
                selector: "#payment-method",
                variantKey: order.paymentMethod,
            }),

            // ------  이용약관 UI 렌더링 ------
            await widgets.renderAgreement({ selector: "#agreement", variantKey: "AGREEMENT" });

            this.$refs.tosspayments.style.display = "";
        },
        async request(){
            var widgets = this.widgets;
            // ------ '결제하기' 버튼 누르면 결제창 띄우기 ------
            await widgets.requestPayment({
                orderId: this.order.orderNo,
                orderName: this.order.orderName,
                successUrl: window.location.origin + "/api/plugins/toss/tosspayments/confirm",
                failUrl: window.location.origin + `/api/plugins/toss/tosspayments/fail`,
                customerEmail: this.order.buyer.email,
                customerName: this.order.buyer.name,
                customerMobilePhone: this.order.buyer.phone,
            });
        },
        cancel(){
            window.tossCancel?.();
        }
    },
};
</script>

<style scoped>
#toss .screen {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 99999;
    overflow-y: scroll;
    background: rgba(0,0,0,0.5);
}
#toss iframe {
    position: fixed;
    width: 100%;
    height: 100%;
}
.toss .progress{
    position: fixed;
    width: 100%;
    height: 100%;
    left:0;
    top: 0;
    background: rgba(0,0,0,0.54);
}
.toss .spinner {
    position: fixed;
    margin: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 28px;
    width: 28px;
    animation: rotate 0.8s infinite linear;
    border: 8px solid #fff;
    border-right-color: transparent;
    border-radius: 50%;
}
@keyframes rotate {
    0%    { transform: rotate(0deg); }
    100%  { transform: rotate(360deg); }
}

.tosspayments {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    max-width: 540px;
    margin: auto;
    background: #ffffff;
    padding-bottom: 24px;
}
.tosspayments .btn-wrapper {
    padding: 0 24px;
}
.tosspayments .btn {
    width: 100%;
    padding: 11px 22px;
    border: none;
    border-radius:  8px;

    background-color: #3282f6;
    color: #f9fcff;
    font-weight: 600;
    font-size: 17px;
    cursor: pointer;
}
.tosspayments .btn {
    width: 100%;
    padding: 11px 22px;
    border: none;
    border-radius:  8px;

    background-color: #3282f6;
    color: #f9fcff;
    font-weight: 600;
    font-size: 17px;
    cursor: pointer;
}
.tosspayments .btn.cancel {
    width: 100%;
    padding: 11px 22px;
    border: 1px solid black;
    border-radius:  8px;
    margin-top: 8px;

    background-color: #ffffff;
    color: black;
    font-weight: 600;
    font-size: 17px;
    cursor: pointer;
}
@media (max-width: 1024px) {
    .tosspayments {
        height: 100%;
        display: flex;
        flex-direction: column;
    }
    #agreement {
        margin-top: auto;
    }
}
</style>
