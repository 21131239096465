var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "toss",
    attrs: {
      "id": "toss"
    }
  }, [_vm.show ? _c('div', {
    ref: "screen",
    staticClass: "screen"
  }, [_c('div', {
    ref: "tosspayments",
    staticClass: "tosspayments"
  }, [_c('div', {
    attrs: {
      "id": "payment-method"
    }
  }), _c('div', {
    attrs: {
      "id": "agreement"
    }
  }), _c('div', {
    staticClass: "btn-wrapper"
  }, [_c('button', {
    staticClass: "btn",
    attrs: {
      "id": "payment-button"
    },
    on: {
      "click": function ($event) {
        return _vm.request();
      }
    }
  }, [_vm._v("결제하기")]), _c('button', {
    staticClass: "btn cancel",
    attrs: {
      "id": "payment-button"
    },
    on: {
      "click": function ($event) {
        return _vm.cancel();
      }
    }
  }, [_vm._v("취소하기")])])])]) : _vm._e(), _vm.show ? _c('div', {
    ref: "progress",
    staticClass: "progress"
  }, [_c('div', {
    staticClass: "spinner"
  })]) : _vm._e()]);

}
var staticRenderFns = []

export { render, staticRenderFns }